import { acceptHMRUpdate, defineStore } from 'pinia'

export const useConnectWalletModalStore = defineStore('connectWalletModalStore', {
  state: () => ({
    connectWalletIsVisible: false,
  }),
  actions: {
    showConnectWalletModal() {
      this.connectWalletIsVisible = true
    },
    hideConnectWalletModal() {
      this.connectWalletIsVisible = false
    },
  },
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConnectWalletModalStore, import.meta.hot))
}
